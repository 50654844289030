import React, { Component } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

class Testimonials extends Component {
render() {

  const images = [
    {
      original: 'images/gallery/14.jpg',
      thumbnail: 'images/gallery/14.jpg',
    },
    {
      original: 'images/gallery/Malaysia press coverage.jpg',
      thumbnail: 'images/gallery/Malaysia press coverage.jpg'
    },
    {
      original: 'images/gallery/413085_366104730110084_92616554_o.jpg',
      thumbnail: 'images/gallery/413085_366104730110084_92616554_o.jpg'
    },
    {
      original: 'images/gallery/Addressing Malaysia SME seminar 2003.jpg',
      thumbnail: 'images/gallery/Addressing Malaysia SME seminar 2003.jpg'
    },
    {
      original: 'images/gallery/APJ Abdul Kalam and YRDSC_0169 (1).jpg',
      thumbnail: 'images/gallery/APJ Abdul Kalam and YRDSC_0169 (1).jpg'
    },
    {
      original: 'images/gallery/2.jpg',
      thumbnail: 'images/gallery/2.jpg'
    },
    {
      original: 'images/gallery/3.jpg',
      thumbnail: 'images/gallery/3.jpg'
    },
    {
      original: 'images/gallery/8.jpg',
      thumbnail: 'images/gallery/8.jpg'
    },
    {
      original: 'images/gallery/13.jpg',
      thumbnail: 'images/gallery/13.jpg'
    },
    {
      original: 'images/gallery/19.jpg',
      thumbnail: 'images/gallery/19.jpg'
    },
    {
      original: 'images/gallery/21.jpg',
      thumbnail: 'images/gallery/21.jpg'
    },
    {
      original: 'images/gallery/24.jpg',
      thumbnail: 'images/gallery/24.jpg'
    },
    {
      original: 'images/gallery/b1yr-2.jpg',
      thumbnail: 'images/gallery/b1yr-2.jpg'
    },
    {
      original: 'images/gallery/b2yr-3.jpg',
      thumbnail: 'images/gallery/b2yr-3.jpg'
    },
    {
      original: 'images/gallery/DSC_0016.jpg',
      thumbnail: 'images/gallery/DSC_0016.jpg'
    },
    {
      original: 'images/gallery/DSC_0075.jpg',
      thumbnail: 'images/gallery/DSC_0075.jpg'
    },
    {
      original: 'images/gallery/DSC_1383.jpg',
      thumbnail: 'images/gallery/DSC_1383.jpg'
    },
    {
      original: 'images/gallery/DSC_2500.jpg',
      thumbnail: 'images/gallery/DSC_2500.jpg'
    },
    {
      original: 'images/gallery/DSC_2726.jpg',
      thumbnail: 'images/gallery/DSC_2726.jpg'
    },
    {
      original: 'images/gallery/Global Event series on Risk management.jpg',
      thumbnail: 'images/gallery/Global Event series on Risk management.jpg'
    },
    {
      original: 'images/gallery/IMG_7980.jpg',
      thumbnail: 'images/gallery/IMG_7980.jpg'
    },
    {
      original: 'images/gallery/International Seminar on Risk mgt with Steve Lyndo.jpg',
      thumbnail: 'images/gallery/International Seminar on Risk mgt with Steve Lyndo.jpg'
    },
    {
      original: 'images/gallery/Kabir Bedi launching ASIB with R. Durgadoss.jpg',
      thumbnail: 'images/gallery/Kabir Bedi launching ASIB with R. Durgadoss.jpg'
    },
    {
      original: 'images/gallery/Narketpalli solar farm.jpg',
      thumbnail: 'images/gallery/Narketpalli solar farm.jpg'
    },
    {
      original: 'images/gallery/Moments of joy.jpg',
      thumbnail: 'images/gallery/Moments of joy.jpg'
    },
    {
      original: 'images/gallery/Subir Gokaran at Internatinal Workshop on Quant finance  video address.jpg',
      thumbnail: 'images/gallery/Subir Gokaran at Internatinal Workshop on Quant finance  video address.jpg'
    },
    {
      original: 'images/gallery/Think Tank IHC.jpg',
      thumbnail: 'images/gallery/Think Tank IHC.jpg'
    },
    {
      original: 'images/gallery/vijayakumar grtg.jpg',
      thumbnail: 'images/gallery/vijayakumar grtg.jpg'
    },
    {
      original: 'images/gallery/with KV Kamat and Sambasimurty of IDRBT.jpg',
      thumbnail: 'images/gallery/with KV Kamat and Sambasimurty of IDRBT.jpg'
    },
    {
      original: 'images/gallery/with RBI Governor Subbarao.jpg',
      thumbnail: 'images/gallery/with RBI Governor Subbarao.jpg'
    }
    
  ]

   return (
     <section ID = "gallery">
    <Carousel>
    <div>
        <img src="images/gallery/14.jpg" />
    </div>
    <div>
        <img src="images/gallery/Malaysia press coverage.jpg" />
    </div>
    <div>
        <img src="images/gallery/413085_366104730110084_92616554_o.jpg" />
    </div>
    <div>
        <img src="images/gallery/20160224_113101.jpg" />
    </div>
    <div>
        <img src="images/gallery/Addressing Malaysia SME seminar 2003.jpg" />
    </div>
    <div>
        <img src="images/gallery/APJ Abdul Kalam and YRDSC_0169 (1).jpg" />
    </div>
    <div>
        <img src="images/gallery/2.jpg" />
    </div>
    <div>
        <img src="images/gallery/3.jpg" />
    </div>
    <div>
        <img src="images/gallery/8.jpg" />
    </div>
    <div>
        <img src="images/gallery/13.jpg" />
    </div>
    <div>
        <img src="images/gallery/19.jpg" />
    </div>
    <div>
        <img src="images/gallery/21.jpg" />
    </div>
    <div>
        <img src="images/gallery/24.jpg" />
    </div>
    <div>
        <img src="images/gallery/b1yr-2.jpg" />
    </div>
    <div>
        <img src="images/gallery/b2yr-3.jpg" />
    </div>
    <div>
        <img src="images/gallery/DSC_0016.jpg" />
    </div>
    <div>
        <img src="images/gallery/DSC_0075.jpg" />
    </div>
    <div>
        <img src="images/gallery/DSC_1383.jpg" />
    </div>
    <div>
        <img src="images/gallery/DSC_2500.jpg" />
    </div>
    <div>
        <img src="images/gallery/DSC_2726.jpg" />
    </div>
    <div>
        <img src="images/gallery/Global Event series on Risk management.jpg" />
    </div>
    <div>
        <img src="images/gallery/IMG_7980.jpg" />
    </div>
    <div>
        <img src="images/gallery/International Seminar on Risk mgt with Steve Lyndo.jpg" />
    </div>
    <div>
        <img src="images/gallery/Kabir Bedi launching ASIB with R. Durgadoss.jpg" />
    </div>
    <div>
        <img src="images/gallery/Narketpalli solar farm.jpg" />
    </div>
    <div>
        <img src="images/gallery/Moments of joy.jpg" />
    </div>
    <div>
        <img src="images/gallery/Subir Gokaran at Internatinal Workshop on Quant finance  video address.jpg" />
    </div>
    <div>
        <img src="images/gallery/Think Tank IHC.jpg" />
    </div>
    <div>
        <img src="images/gallery/vijayakumar grtg.jpg" />
    </div>
    <div>
        <img src="images/gallery/with KV Kamat and Sambasimurty of IDRBT.jpg" />
    </div>
    <div>
        <img src="images/gallery/with RBI Governor Subbarao.jpg" />
    </div>
</Carousel>
</section>
   );
 }
}

export default Testimonials;
